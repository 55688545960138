<script setup lang="ts">
import type { Branches } from "~/types/directus-collections.d.ts";

const props = defineProps<{
  branch: Branches;
}>();

const parsedDescription = ref<string | null>(null);
const memberCount = ref<number | null>(null);

if (props.branch.description) {
  parsedDescription.value = props.branch.description.replace(/<\/?[^>]+(>|$)/g, "");

  // trim description to 325 characters
  if (parsedDescription.value.length > 325) {
    parsedDescription.value = parsedDescription.value.substring(0, 325) + "...";
  }
}

if (props.branch.branch_members_count) {
  memberCount.value = props.branch.branch_members_count;
}
</script>

<template>
  <div class="relative flex flex-col items-stretch bg-white xl:max-h-[350px] xl:flex-row">
    <div class="absolute left-2 top-2 z-10 rounded-md bg-primary p-2 text-white">{{ branch.category }} - {{ memberCount }} Members</div>
    <CommonDirectusImage
      v-if="branch.branch_preview_image || branch.banner_image"
      class="max-h-[300px] grow bg-tertiary object-cover xl:max-h-[350px] xl:w-2/5"
      :img="branch.branch_preview_image ? branch.branch_preview_image : branch.banner_image ? branch.banner_image : '/branches-banner.png'"
      preset="gallery"
    />
    <div v-else class="flex max-h-[300px] grow items-center justify-center bg-tertiary object-cover xl:max-h-[350px] xl:w-2/5">
      <img src="/single-footprint.svg" alt="" class="size-36" />
    </div>
    <div class="flex flex-col justify-between gap-5 px-12 py-10 text-primary xl:w-3/5">
      <NuxtLink :to="{ name: 'groups-slug', params: { slug: branch.slug } }">
        <h3 class="text-2xl">{{ branch.title }}</h3>
      </NuxtLink>
      <p class="text-lg">{{ parsedDescription }}</p>
      <div class="flex flex-wrap items-center gap-4 pt-4">
        <CommonButtonNuxtLink class="grow" :to="{ name: 'groups-slug', params: { slug: branch.slug } }">Group Details</CommonButtonNuxtLink>
        <CommonButtonNuxtLink
          v-if="branch.accept_donation && branch.stripe_id"
          class="grow"
          icon="fa-solid fa-hand-holding-dollar"
          btn-style="secondary"
          btn-hover-style="primary"
          :to="{ name: 'groups-slug', params: { slug: branch.slug } }"
          >Support</CommonButtonNuxtLink
        >
      </div>
    </div>
  </div>
</template>
